<template>
  <BaseCard>
    <BaseCardHeader :loading="loading">
      <span class="flex items-center justify-center md:justify-start">
        Shift Availability
        <span class="ml-2">
          <BaseIconButton
            rounded
            size="md"
            color="text-white hover:text-gray-300"
            @click="setKeyVisibility(!keyIsVisible)"
          >
            <InfoIcon />
          </BaseIconButton>
        </span>
        <span
          v-if="editing"
          class="text-blue-light uppercase ml-2"
        >
          Editing
        </span>
      
        <StaffPreferencesKey 
          :is-visible="keyIsVisible"
          @leaving="setKeyVisibility(false)"
        />
      </span>
      <template #headingRight>
        <div class="flex items-center justify-center md:jusify-start pb-3 md:p-0">
          <span
            v-if="editing"
            class="flex items-center gap-x-3"
          >
            <transition name="fade">
              <BaseButton
                v-if="hasChanges"
                theme="success"
                size="xs"
                :working="submitting"
                @click="onSubmitUpdate"
              >
                Save changes
              </BaseButton>
            </transition>
            <BaseIconButton
              rounded
              size="md"
              color="text-white hover:text-gray-300"
              :disabled="submitting"
              @click="cancelEditing"
            >
              <CloseIcon />
            </BaseIconButton>
          </span>
          <BaseIconButton
            v-else
            rounded
            size="full"
            :disabled="submitting"
            @click="startEditing"
          >
            <EditIcon />
          </BaseIconButton>
        </div>
      </template>
    </BaseCardHeader>

    <div
      v-if="localLoading"
      class="grid-container border-t border-white"
    >
      <div
        v-for="i in Array(7)"
        :key="i"
        class="bg-blue-lightest pulse flex items-center justify-center mr-4"
      >
        <p class="italic xxl:text-lg w-full flex items-center pulse bg-blue-lightest h-8 rounded-full pl-4 -mr-4" />
      </div>
      <div
        v-for="ind in Array(35)"
        :key="ind"
        class="grid-item my-1 w-9 h-9 md:w-12 md:h-12 xxl:w-14 xxl:h-14 mx-auto bg-blue-lightest pulse rounded-full"
      />
      <div
        v-for="idx in Array(7)"
        :key="idx"
        class="bg-blue-lightest pulse flex items-center justify-start ml-4"
      >
        <span class="block bg-blue-lightest pulse h-8 w-8 rounded-full -ml-2" />
      </div>
    </div>

    <div
      v-else-if="tableData.length"
      class="grid-container"
    >
      <!-- Row names -->
      <div
        v-for="name in tableRowNames"
        :key="name"
        class="bg-blue-light flex items-center justify-center mr-2 md:mr-4"
      >
        <p class="italic flex items-center bg-blue-light w-12 md:w-16 h-4 md:h-8 rounded-full pl-2 -mr-1 md:-mr-2 md:pl-3 text-xs md:text-sm">
          <span>{{ name.substring(0, 3).toUpperCase() }}</span>
        </p>
      </div>

      <!-- Slots -->
      <div
        v-for="option in tableData"
        :key="option.id"
        class="grid-item my-1"
      >
        <StaffPreferencesOption
          :option-id="option.id"
          :state="option.state"
          :readonly="!editing || (option.state === 0 && isNotAdmin)"
          :disabled="submitting"
          @toggle="updatePreference"
        >
          <span class="md:hidden">{{ nameToInitials(option.label) }}</span>
          <span class="hidden md:block">{{ option.label }}</span>
        </StaffPreferencesOption>
      </div>

      <!-- Row end pattern -->
      <div
        v-for="idx in Array(7)"
        :key="idx"
        class="bg-blue-light flex items-center justify-start ml-2 md:ml-4"
      >
        <span class="block bg-blue-light h-4 w-4 md:h-8 md:w-8 rounded-full -ml-1 md:-ml-2" />
      </div>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { SlotPreference, SlotOption, UserSuccessResponse, UserPreferences } from '@/types/users'
import { defineComponent, PropType, ref, toRefs, watch, computed } from 'vue'
import useToasts from '@/hooks/useToasts'
import useRole from '@/hooks/useRole'
import UsersAPI from '@/apis/rota-architect/users'
import parseErrorMap from '@/utils/parseErrorMap'
import nameToInitials from '@/utils/nameToInitials'

import StaffPreferencesOption from './StaffPreferencesOption.vue'
import StaffPreferencesKey from './StaffPreferencesKey.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import CloseIcon from '@/components/icons/toast/CloseIcon.vue'
import InfoIcon from '@/components/icons/toast/InfoIcon.vue'

interface TableOption {
  id: number;
  label: string;
  state: number;
}

export default defineComponent({
  components: {
    StaffPreferencesOption,
    StaffPreferencesKey,
    EditIcon,
    CloseIcon,
    InfoIcon,
  },

  props: {
    initialPreferred: {
      type: Array as PropType<SlotPreference[]>,
      required: true,
    },
    initialUnavailable: {
      type: Array as PropType<SlotPreference[]>,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  setup (props) {
    const dispatch = useToasts()
    const role = useRole()

    const preferred = ref<number[]>([])
    const unavailable = ref<number[]>([])

    const slotOptions = ref<SlotOption[]>([])
    const loadingOptions = ref(true)
    const tableData = ref<TableOption[]>([])
    const tableRowNames = ref<string[]>([])

    const createTableData = () => {
      // generate data structure to render the table
      // add a state prop to to each item in options
      const tempArr: string[] = []
      tableData.value = slotOptions.value.map((opt: SlotOption) => {
        // create a list of row names
        tempArr.push(opt.day)

        // is the slot preferred?
        let state = preferred.value.includes(opt.id) ? 2 : 0

        if (state === 0) {
          // is the slot unavailable? else mark neutral '1'
          state = unavailable.value.includes(opt.id) ? 0 : 1
        }

        return {
          id: opt.id,
          label: opt.slot,
          state,
        }
      })

      // generate unique row names
      tableRowNames.value = [ ...new Set(tempArr)]
    }

    const fetchOptions = () => {
      UsersAPI.getSlotPreferenceOptions()
      .then((res) => {
        slotOptions.value = res.data
        createTableData()
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loadingOptions.value = false
      })
    }


    const { loading } = toRefs(props)
    watch(loading, (newValue: boolean, oldValue: boolean) => {
      if (oldValue && !newValue) { // profile data ready
        preferred.value = props.initialPreferred.map(p => p.preference_id)
        unavailable.value = props.initialUnavailable.map(p => p.preference_id)

        fetchOptions()
      }
    })

    const localLoading = computed(() => {
      if (props.loading) return true
      if (loadingOptions.value) return true
      return false
    })

    const editing = ref(false)
    const submitting = ref(false)
    const hasChanges = ref(false)

    const startEditing = () => {
      editing.value = true
    }

    const cancelEditing = () => {
      editing.value = false

      if (hasChanges.value) {
        createTableData() // reset to initial
        hasChanges.value = false
      }
    }

    const updatePreference = (id: number, newState: number) => {
      const idx = tableData.value.findIndex((el) => el.id === id)

      if (idx >= 0) {
        tableData.value[idx] = {
          ...tableData.value[idx],
          state: newState,
        }

        if (!hasChanges.value) {
          hasChanges.value = true
        }
      }
    }

    const onSubmitUpdate = () => {
      if (submitting.value || !editing.value) return

      submitting.value = true

      // form the payload.
      const pref: number[] = []
      const unvail: number[] = []

      tableData.value.forEach((opt) => {
        switch (opt.state) {
          case 0:
            unvail.push(opt.id)  
            break
          case 2:
            pref.push(opt.id)
            break
          default:
        }
      })

      UsersAPI.updateSlotPreferences(
        props.userId.toString(), 
        { 
          preferred: pref,
          unavailable: unvail,
        },
      )
        .then((res: { data: UserSuccessResponse & UserPreferences }) => {
          dispatch.successToast('Preferences updated successfully.')
          preferred.value = res.data.preferred.map((p: SlotPreference) => p.preference_id)
          unavailable.value = res.data.unavailable.map((p: SlotPreference) => p.preference_id)
          hasChanges.value = false
          editing.value = false
        })
        .catch(() => {
          dispatch.errorToast('Failed to update preferences.')
        })
        .finally(() => {
          submitting.value = false
        })
    }

    const keyIsVisible = ref(false)

    const setKeyVisibility = (val: boolean) => {
      keyIsVisible.value = val
    }

    return {
      localLoading,
      tableData,
      tableRowNames,
      updatePreference,
      editing,
      startEditing,
      cancelEditing,
      hasChanges,
      onSubmitUpdate,
      submitting,
      isNotAdmin: role.isStaff.value,
      keyIsVisible,
      setKeyVisibility,
      nameToInitials,
    }
  },
})
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 50px 2fr 2fr 2fr 2fr 2fr 1fr;
  grid-template-rows: repeat(7, 1fr);
  grid-auto-flow: column;
  grid-column-gap: 2px;

  @screen md {
    grid-template-columns: 70px 2fr 2fr 2fr 2fr 2fr 1fr;
    column-gap: 6px;
  } 
}
</style>