
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['leaving'],

  setup () {
    

    return {}
  },
})
