<template>
  <BaseCard>
    <BaseCardHeader :loading="loading">
      Personal Details
      <template #headingRight>
        <div class="flex items-center justify-center md:jusify-start pb-3 md:p-0">
          <RouterLink :to="{ name: 'edit-staff', params: { userId: userId }}">
            <BaseIconButton
              rounded
              size="full"
            >
              <EditIcon />
            </BaseIconButton>
          </RouterLink>
          <div class="flex items-center justify-center md:jusify-start" />
        </div>
      </template>
    </BaseCardHeader>
    
    <div class="py-4">
      <template v-if="loading">
        <span
          v-for="item in Array(6)"
          :key="item"
          :class="itemWrapperClasses"
        >
          <p
            class="pulse h-6 bg-blue-lightest"
            :class="[labelClasses]"
          />
          <p class="pulse h-6 bg-blue-lightest w-full" />
        </span>
        <span class="h-6 block my-4" />
        <span
          v-for="item in Array(3)"
          :key="item"
          :class="itemWrapperClasses"
        >
          <p
            class="pulse h-6 bg-blue-lightest"
            :class="[labelClasses]"
          />
          <p class="pulse h-6 bg-blue-lightest w-full" />
        </span>
      </template>
      <template v-else>
        <span
          v-for="(item, idx) in detailsArray"
          :key="idx"
          :class="itemWrapperClasses"
        >
          <p :class="labelClasses">
            {{ item.label }}:
          </p>
          <a
            v-if="item.label === 'Phone'"
            :href="`tel:${item.value}`"
            class="hover:underline"
          >
            {{ item.value }}
          </a>
          <a
            v-else-if="item.label === 'Email'"
            :href="`mailto:${item.value}`"
            class="hover:underline"
          >
            {{ item.value }}
          </a>
          <p
            v-else
            class="break-all"
          >
            {{ item.value }}
          </p>
        </span>
        <span
          v-if="entitlementArray.length"
          class="w-full flex bg-grey my-4"
        >
          <p class="w-1/3 text-right flex-shrink-0 font-bold text-white italic">
            Entitlement
          </p>
          <p class="font-bold text-white italic pl-4">
            (2020/21)
          </p>
        </span>
        <span
          v-for="(item, idx) in entitlementArray"
          :key="idx"
          class="italic"
          :class="itemWrapperClasses"
        >
          <p :class="labelClasses">
            {{ item.label }}:
          </p>
          <p>
            {{ item.value }}
          </p>
        </span>
        <section
          v-if="adminArray.length"
          class="bg-blue-light py-2 mt-4"
          :class="{ 'mb-4': invitable || reinvitable }"
        >
          <span
            v-for="(item, idx) in adminArray"
            :key="idx"
            class="italic"
            :class="itemWrapperClasses"
          >
            <p :class="labelClasses">
              {{ item.label }}:
            </p>
            <p class="flex">
              {{ item.value }}
              <span
                v-if="item.emitUpdateRole"
                class="ml-3"
              >
                <BaseIconButton
                  rounded
                  size="full"
                  @click="$emit('update-role')"
                >
                  <EditIcon />
                </BaseIconButton>
              </span>
            </p>
          </span>
        </section>
        <span class="block px-2 md:px-4">
          <BaseButton
            v-if="reinvitable"
            theme="base"
            size="full"
            :working="inviting"
            @click="$emit('invite')"
          >
            Resend Invite
          </BaseButton>
          <BaseButton
            v-else-if="invitable"
            theme="base"
            size="full"
            :working="inviting"
            @click="$emit('invite')"
          >
            Invite to Register
          </BaseButton>
        </span>
      </template>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import EditIcon from '@/components/icons/EditIcon.vue'

interface Detail {
  label: string;
  value: string;
}

export default defineComponent({
  components: {
    EditIcon,
  },
  
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    detailsArray: {
      type: Array as PropType<Detail[]>,
      required: true,
    },
    entitlementArray: {
      type: Array as PropType<Detail[]>,
      required: true,
    },
    adminArray: {
      type: Array as PropType<Detail[]>,
      default: () => ([]),
    },
    userId: {
      type: String,
      required: true,
    },
    inviting: {
      type: Boolean,
      default: false,
    },
    invitable: {
      type: Boolean,
      default: false,
    },
    reinvitable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['invite', 'update-role'],

  setup () {
    
    return {
      itemWrapperClasses: 'flex w-full mb-1 px-2 md:px-4',
      labelClasses: 'w-1/3 text-right flex-shrink-0 font-bold text-gray-900 mr-4',
    }
  },
})
</script>

<style scoped>

</style>