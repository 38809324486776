
import { defineComponent, PropType } from 'vue'
import EditIcon from '@/components/icons/EditIcon.vue'

interface Detail {
  label: string;
  value: string;
}

export default defineComponent({
  components: {
    EditIcon,
  },
  
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    detailsArray: {
      type: Array as PropType<Detail[]>,
      required: true,
    },
    entitlementArray: {
      type: Array as PropType<Detail[]>,
      required: true,
    },
    adminArray: {
      type: Array as PropType<Detail[]>,
      default: () => ([]),
    },
    userId: {
      type: String,
      required: true,
    },
    inviting: {
      type: Boolean,
      default: false,
    },
    invitable: {
      type: Boolean,
      default: false,
    },
    reinvitable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['invite', 'update-role'],

  setup () {
    
    return {
      itemWrapperClasses: 'flex w-full mb-1 px-2 md:px-4',
      labelClasses: 'w-1/3 text-right flex-shrink-0 font-bold text-gray-900 mr-4',
    }
  },
})
