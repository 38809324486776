<template>
  <div
    class="absolute top-8 w-48 rounded-md shadow-lg transition ease-out bg-white z-10"
    @mouseleave="$emit('leaving')"
    :class="[
      isVisible
        ? 'opacity-100 scale-100 duration-200'
        : 'opacity-0 scale-95 pointer-events-none duration-100'
    ]"
  >
    <div class="p-4">
      <ul>
        <li class="flex items-center text-gray-900 mb-2">
          <div class="w-8 h-8 rounded-full bg-green-medium mr-3" />
          <p>
            Preferred
          </p>
        </li>
        <li class="flex items-center text-gray-900 mb-2">
          <div class="w-8 h-8 rounded-full bg-blue-medium mr-3" />
          <p>
            No preference
          </p>
        </li>
        <li class="flex items-center text-gray-900">
          <div class="w-8 h-8 rounded-full bg-grey mr-3" />
          <p>
            Unavailable
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['leaving'],

  setup () {
    

    return {}
  },
})
</script>
