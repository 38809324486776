
import { StringMap } from '@/types/base'
import { defineComponent, computed, PropType } from 'vue'
import useRole from '@/hooks/useRole'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    state: {
      type: Number as PropType<0 | 1 | 2>,
      default: 1,
    },
    optionId: {
      type: Number,
      required: true,
    },
  },

  emits: ['toggle'],

  setup (props, ctx) {
    const role = useRole()

    const stateClassesMap: StringMap = {
      0: 'bg-gray-900 hover:bg-gray-800', // Unavailable
      1: 'bg-blue-medium hover:bg-blue-dark', // Neutral
      2: 'bg-green-medium hover:bg-green-dark', // Preferred
    }

    const stateClasses = computed(() => stateClassesMap[props.state])

    const toggle = () => {
      if (props.disabled) return

      let newState
      if (role.isStaff.value) {
        // no option to mark 0 (unavailable)
        newState = props.state === 2 ? 1 : props.state + 1
      } else {
        newState = props.state === 2 ? 0 : props.state + 1
      }

      ctx.emit(
        'toggle', 
        props.optionId, 
        newState, 
        props.state, // prevState
      )
    }

    return {
      baseClasses: 'w-10 h-10 md:w-12 md:h-12 xxl:w-14 xxl:h-14 flex items-center justify-center rounded-full text-white flex-shrink-0 text-xs md:text-sm mx-auto focus:outline-none',
      stateClasses,
      toggle,
    }
  },
})
