
import { SlotPreference, SlotOption, UserSuccessResponse, UserPreferences } from '@/types/users'
import { defineComponent, PropType, ref, toRefs, watch, computed } from 'vue'
import useToasts from '@/hooks/useToasts'
import useRole from '@/hooks/useRole'
import UsersAPI from '@/apis/rota-architect/users'
import parseErrorMap from '@/utils/parseErrorMap'
import nameToInitials from '@/utils/nameToInitials'

import StaffPreferencesOption from './StaffPreferencesOption.vue'
import StaffPreferencesKey from './StaffPreferencesKey.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import CloseIcon from '@/components/icons/toast/CloseIcon.vue'
import InfoIcon from '@/components/icons/toast/InfoIcon.vue'

interface TableOption {
  id: number;
  label: string;
  state: number;
}

export default defineComponent({
  components: {
    StaffPreferencesOption,
    StaffPreferencesKey,
    EditIcon,
    CloseIcon,
    InfoIcon,
  },

  props: {
    initialPreferred: {
      type: Array as PropType<SlotPreference[]>,
      required: true,
    },
    initialUnavailable: {
      type: Array as PropType<SlotPreference[]>,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  setup (props) {
    const dispatch = useToasts()
    const role = useRole()

    const preferred = ref<number[]>([])
    const unavailable = ref<number[]>([])

    const slotOptions = ref<SlotOption[]>([])
    const loadingOptions = ref(true)
    const tableData = ref<TableOption[]>([])
    const tableRowNames = ref<string[]>([])

    const createTableData = () => {
      // generate data structure to render the table
      // add a state prop to to each item in options
      const tempArr: string[] = []
      tableData.value = slotOptions.value.map((opt: SlotOption) => {
        // create a list of row names
        tempArr.push(opt.day)

        // is the slot preferred?
        let state = preferred.value.includes(opt.id) ? 2 : 0

        if (state === 0) {
          // is the slot unavailable? else mark neutral '1'
          state = unavailable.value.includes(opt.id) ? 0 : 1
        }

        return {
          id: opt.id,
          label: opt.slot,
          state,
        }
      })

      // generate unique row names
      tableRowNames.value = [ ...new Set(tempArr)]
    }

    const fetchOptions = () => {
      UsersAPI.getSlotPreferenceOptions()
      .then((res) => {
        slotOptions.value = res.data
        createTableData()
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loadingOptions.value = false
      })
    }


    const { loading } = toRefs(props)
    watch(loading, (newValue: boolean, oldValue: boolean) => {
      if (oldValue && !newValue) { // profile data ready
        preferred.value = props.initialPreferred.map(p => p.preference_id)
        unavailable.value = props.initialUnavailable.map(p => p.preference_id)

        fetchOptions()
      }
    })

    const localLoading = computed(() => {
      if (props.loading) return true
      if (loadingOptions.value) return true
      return false
    })

    const editing = ref(false)
    const submitting = ref(false)
    const hasChanges = ref(false)

    const startEditing = () => {
      editing.value = true
    }

    const cancelEditing = () => {
      editing.value = false

      if (hasChanges.value) {
        createTableData() // reset to initial
        hasChanges.value = false
      }
    }

    const updatePreference = (id: number, newState: number) => {
      const idx = tableData.value.findIndex((el) => el.id === id)

      if (idx >= 0) {
        tableData.value[idx] = {
          ...tableData.value[idx],
          state: newState,
        }

        if (!hasChanges.value) {
          hasChanges.value = true
        }
      }
    }

    const onSubmitUpdate = () => {
      if (submitting.value || !editing.value) return

      submitting.value = true

      // form the payload.
      const pref: number[] = []
      const unvail: number[] = []

      tableData.value.forEach((opt) => {
        switch (opt.state) {
          case 0:
            unvail.push(opt.id)  
            break
          case 2:
            pref.push(opt.id)
            break
          default:
        }
      })

      UsersAPI.updateSlotPreferences(
        props.userId.toString(), 
        { 
          preferred: pref,
          unavailable: unvail,
        },
      )
        .then((res: { data: UserSuccessResponse & UserPreferences }) => {
          dispatch.successToast('Preferences updated successfully.')
          preferred.value = res.data.preferred.map((p: SlotPreference) => p.preference_id)
          unavailable.value = res.data.unavailable.map((p: SlotPreference) => p.preference_id)
          hasChanges.value = false
          editing.value = false
        })
        .catch(() => {
          dispatch.errorToast('Failed to update preferences.')
        })
        .finally(() => {
          submitting.value = false
        })
    }

    const keyIsVisible = ref(false)

    const setKeyVisibility = (val: boolean) => {
      keyIsVisible.value = val
    }

    return {
      localLoading,
      tableData,
      tableRowNames,
      updatePreference,
      editing,
      startEditing,
      cancelEditing,
      hasChanges,
      onSubmitUpdate,
      submitting,
      isNotAdmin: role.isStaff.value,
      keyIsVisible,
      setKeyVisibility,
      nameToInitials,
    }
  },
})
